import { io } from "socket.io-client";

const socket = io("https://api-live.estility.co/estility-socket-server/");
// const socket = io("https://estility-socket-server-dbcb5649270b.herokuapp.com/");

socket.on("connect", () => {
  console.log("in socket");
  if (localStorage.getItem("vmsusername")) {
    socket.emit("updateSocketID", localStorage.getItem("vmsusername"));
  }
});

export default socket;
